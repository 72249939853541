@import "config.scss";

.home-release {
    grid-template-columns: repeat(auto-fill,minmax(182px,1fr));
    justify-content: center;
    display: grid;
}

.MuiFormControl-root {
    font: inherit;
    vertical-align: middle;
    -webkit-appearance: none;
    display: flex;
    padding: .1rem 1rem;
    width: 100%;
        background: #121212;
    border: 2px solid #282a2d;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-filled {
    font-size: 14px;
    font-family: inherit;
}

.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1, .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-filled.MuiInputBase-input.MuiInput-input {
    font-family: cabin,sans-serif;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-filled.MuiInputBase-input.MuiInput-input {
    padding: 5px;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl:before{
	border-bottom: 1px solid #171717;
}

.similarpic .coveri {
    position: absolute;
    left: 0;
    width: 205px;
    bottom: -80px;
    left: -38px;
}
.similardd {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding-right: 20px;
}
.loading.a {
    height: 200px;
}

.loading.a>.spinner {
    margin-top: 100px;
}
.similarc {
    width: 480px;
    height: 64px;
    float: left;
    margin-left: 1px;
}

.similarpic {
    width: 114px;
    height: 64px;
    float: left;
    overflow: hidden;
    position: relative;
}

.similard {
    width: 365px;
    height: 64px;
    float: left;
    background-color: #121212;
    text-align: left;
    font-size: 16px;
    font-family: myriad;
    color: #fff;
    padding-left: 20px;
    letter-spacing: 1px;
}

.pg2box {
    width: 100%;
    text-align: center;
    margin-right: 14px;
    top: -8px;
    width: 85%;
}

.similarcmain {
    width: 480px;
    height: 65px;
    float: left;
    margin-left: -1px;
    background-color: #333;
}

@media (max-width: 1280px) {
.pg2box {
    width: 958px;
}

}

@media (max-width: 960px) {
    .pg2box {
    width: 638px;
}.similard {
    width: 524px;
}
.similarcmain {
    width: 641px;
    height: 65px;
    margin-right: 0;
}
.similarc {
    width: 640px;
    height: 64px;
    margin-right: 0;
}
}

@media (max-width: 640px) {
.pg2box {
    width: 317px;
}
.similarcmain {
    width: 320px;
}
.similarc {
    width: 319px;
}
.similard {
    width: 203px;
    font-size: 14px;
}
}