.base-register-inner {
    box-shadow: 0 0 40px #000;
    width: 100vw;
    height: 100%;
    max-width: 450px;
    max-height: 700px;
    background: #121212;
    display: flex;
    //padding-top: 40px;
    flex-direction: column;
}

.register-nav {
    position: relative;
    display: flex;

    .nav-item-cont {
        flex: 1;
        .register-nav-item {
            color: #ccc;

            padding: 15px 10px;
            text-align: center;
            background-color: inherit;
            border: 1px solid #333333;
            box-sizing: border-box;

            &:hover {
                opacity: 0.8;
            }
        }
        .nav-active {
            .register-nav-item {
                color: #e81e30;
            }
        }
        &:first-child .register-nav-item {
            border-right: none;
        }
    }
}

a {
    font-size: 15px;
    color: #e81e30;
    &:hover {
        color: #ccc;
    }
}
.form-header {
    padding: 0 10px;
    text-align: center;
    font-family: myriadlight;
    border-bottom: 1px solid #333333;
    padding: 15px 0;
    box-sizing: border-box;
    border-top: none;
    h1 {
        margin: 0;
    }
}
.form-inputs {
    .input-cont {
        position: relative;
        width: 100%;
        padding: 10px 30px;
        @media (max-width: 1280px) {
            padding: 10px;
        }

        padding-bottom: 0;
    }
    .searchinput {
        padding-left: 10px;
        width: 100%;
        margin: 0;
        box-sizing: border-box;
    }
    .submit-button {
        cursor: pointer;
        padding: 20px;
        text-align: center;
        border: none;
        background: #e81e30;
        margin: 0;
        width: 100%;
        font-size: inherit;
        color: inherit;

        &:hover {
            opacity: 0.8;
        }
    }
}
.register-body {
    height: 100%;
    flex: 1;
    border: 1px solid #333333;
    border-top: none;
    position: relative;
}
/* .register {
    
} */

.register-close-button {
    position: absolute;
    bottom: 0;
    right: 0;
    button {
        cursor: pointer;
        border: none;
        background: #e81e30;
        padding: 10px;
        color: #ccc;
        &:hover {
            opacity: 0.8;
        }
    }
}
