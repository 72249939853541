$background: #101010;
$background2: #171717;
$background3: #101010;
$theme: #E4004F;
$color: #ccc;
$color2: #fff;
$border-radius: 4px;
$border-radius-big: 10px;
$box-shadow: 0 0 15px #000;
$progressbar-color: #e53232;


$page-constrict: 1600px;