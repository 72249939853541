/* .footer {
    width: 100%;
    color: #333;
    background-color: #121212;
    float: left;
    border-top: 1px solid #333333;
}

.footermain {
    max-width: 1240px;
    margin: 0 auto;
    overflow: hidden;
    display: block;
    padding: 20px;
    margin-top: 0;
    background-color: #121212;
    color: #969696;
    font-family: myriadlight;
    font-size: 16px;
    font-style: normal;
}

.socbox {
    padding-right: 0;
    background-color: #121212;
}

.footert2 {
    padding-right: 0;
    padding-top: 9px;
    float: right;
}

.footert {
    margin-top: 0;
    background-color: #121212;
    color: #969696;
    font-family: myriadlight;
    font-style: normal;
    text-align: center;
    padding-right: 0px;
    padding-top: 10px;
    padding-bottom: 11px;
}

.footert2 a, .footert a {
    color: #939393;
} */


.footer {
    
    font-family: arial;
    
    display: flex;
    justify-content: center;

    .footer-cont {
        max-width: 1240px;
        //width: 100%;
        flex: none;
        padding: 1rem;
    }

    .footer-inner {
        
        display: flex;
        justify-content: center;
    }

    .footer-item {
        color: #ccc;
        margin-left: 20px;
        position: relative;

        a {
            color: #ccc;

            &:hover {
                color: #e81e30;
            }
        }

        &::before {
            content: "•";
            position: absolute;
            left: -10px;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            color: #ccc;
        }

        &:first-child {
            &::before {
                content: unset;
            }
            margin-left: 0;
        }
    }
}

.footert2 {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 1000px){
    .footer {
        //margin-left: 20px;
        
        .footer-list {
            display: flex;
        }
        .footer-inner {
            
            flex: 1;
            justify-content: unset;
            flex-direction: column;
            &:last-child {
                align-items: flex-end;
            }
        }
        .footer-item {
            margin-left: 0;
            &::before {
                content: unset
            }
            &:first-child::before {
                //content: "•";
            }
            a {
                font-size: 16px;
            }
        }
    }
}