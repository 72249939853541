.popup {
    position: fixed;
    z-index: 99;
    font-size: 1.1rem;
    font-family: myriadlight;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .15s ease;
    opacity: 0;
    pointer-events: none;

    .popup-inner {
        transition: transform .2s ease;
        position: relative;
        transform: scale(.9);
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    &.popup-shown {
        pointer-events: all;
        opacity: 1;

        .popup-inner {
            transform: scale(1);
        }
    }
    .popup-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: .9);

    }

    .popup-close {
        color: #fff;
        position: relative;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        align-self: flex-end;
        border: 1px solid #333333;
        border-bottom: none;
        &:hover {
            opacity: .8;
        }
        svg {
            position: absolute;
            width: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .close-inner {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        position: relative;
        background: #121212;
        height: 40px;
    }
}