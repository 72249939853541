.donation {
    position: relative;
    width: 100vw;
    max-width: 500px;
    //padding-top: 40px;
    max-height: 100vh;
    background: #121212;
    display: flex;
    flex-direction: column;
    .donation-inner {
        flex: 1;
        
        border: 1px solid #333;
    }
    .donation-padding {
        padding: 0 15px;
    }
    .donation-code {
        font-family: monospace;
        padding: 15px;
        color: #e81e30;
        color: 1.3rem;
        background: #000;
    }
    .qr-code {
        position: relative;
        width: 100%;
        padding: 10px 0;
    }
}

.hr-fake {
    border-bottom: 1px solid #333;
}