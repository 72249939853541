.profile,
.continue-watching {
    font-family: myriadlight;
}
.profile {
    .conm {
        float: unset;
        width: 317px;
    }
    @media (max-width: 640px) {
        & .iepcon {
            width: 117px;
            overflow: hidden;
        }
    }
}
.profile .iepbox {
    border-top: 1px solid #333;
    border-left: 0;
    //border-bottom: 1px solid #333;
    .iep {
        float: none;
    }
    
}
.load-more-cont {
    background: #121212;
    display: flex;
    justify-content: center;
    padding: 10px;
    .load-more {
        padding: 15px;
        border: none;
        background: #e81e30;
        color: inherit;
        margin: 10px 0;
        min-width: 100px;
        font-size: 1.1rem;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }
}
