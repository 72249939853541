.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    border: 0 solid #555658;
    border-top-width: 1px;
}

.container.content {
    margin-top: 25px;
    margin-bottom: 25px;
}

.schedule>span {
    padding: 0 10px;
}

.schedule-group {
    margin-bottom: 15px;
}

.schedule-list-item {
    display: flex;
    cursor: pointer;
    position: relative;
    justify-content: space-between;
    align-items: stretch;
    padding: 10px 30px;
    background-color: #282a2d;
    border-radius: 3px;
}

.anime-group {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    justify-content: center;
    grid-gap: 1em;
    display: grid;
}