.hide {
    display: none !important;
}

.show {
	display: block !important;
}

.pagination-nav-popu {
    float: none;
    display: inline-block;
    margin: 0;
    padding: 0;
}

.pagination-nav-popu>*, .nav-item {
            display: inline-block;
    list-style: none;
    padding: 5px;
    cursor: pointer;
}

.psd {border-top: 1px solid #333333;}

.pg {
    margin: 2px;
    font-size: 12px;
    float: right;
    color: #fff;
}

.nav-item.current {
    background: #e61d30;
    border-radius: 1px;
}