@import "config.scss";

img.sliderimg {
    width: 100%;
    object-fit: cover;
}

.infoboxc {
    float: left;
    width: 956px;
    overflow: hidden;
    background-color: #121212;
    margin-left: 1px;
    border-bottom: 1px solid #333333;
}

.infopicbox {
    float: left;
    padding: 0;
    width: 270px;
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & a {
        margin: 10px 0;
       display: flex;
       justify-content: center;
    }
}

.posteri {
    width: 100%;
}

.infodesbox {
    float: left;
    width: 646px;
    padding: 20px;
}

.textc a {
    color: #e61d2f;
    font-size: 14px;
}

.infodes {
    text-align: left;
    font-size: 19px;
    font-family: myriad;
    color: #fff;
    letter-spacing: 1px;
}

.infodes2 {
    color: #969696;
    text-align: left;
    font-size: 16px;
    font-family: myriadlight;
    letter-spacing: 1px;
}

.infodes h1.entry-title {
    margin: 0;
    font-size: 19px;
}

.textd {
    float: left;
    clear: both;
    padding-bottom: 2px;
    padding-right: 5px;
}

.textc {
    float: left;
    color: #fff;
    padding-bottom: 2px;
    padding-right: 5px;
}

.infovan {
    color: #fff;
    font-family: myriad;
    font-size: 19px;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
}

.infoepmain {
    width: 80px;
    height: 80px;
    background-color: #333;
    float: left;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        transform: scaleX(var(--progress, 0));
        height: 100%;
        transition: transform .1s ease-out;
        background: rgba($color: #fff, $alpha: .2);
        transform-origin: left;
    }
}

.infoep {
    background-image: url(/play.png);
    background-color: #121212;
    background-repeat: no-repeat;
    background-size: 79px 79px;
    width: 79px;
    height: 79px;
    float: left;
    margin-right: 0;
    margin-bottom: 0;

}

.infoept {
    background-color: #121212;
    width: 79px;
    height: 79px;
}

.infoepbox {
    display: inline-block;
    width: 960px;
    background-color: #121212;
    float: left;
    margin-bottom: -1px;
}

.infoept1 {
    text-align: center;
    font-weight: 400;
    height: 25px;
    font-size: 12px;
    font-family: myriadlight;
    color: #fff;
}

.infoept2 {
    text-align: center;
    font-size: 30px;
    height: 29px;
    font-family: myriad;
}

.infoept3 {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    height: 25px;
    font-family: myriadlight;
    color: #fff;
}

@media (max-width: 1280px) {

.infoboxc {
    border-right: 1px solid #333333;
}

}

@media (max-width: 960px) {

.infoepbox {
    width: 640px;
}

.infoboxc {
    width: 637px;
}

.infodesbox {
    width: 327px;
}

}

@media (max-width: 640px) {

.infoboxc {
    width: 316px;
}

.infoepbox {
    width: 320px;
}

.infodesbox {
    width: 280px;
}

.infopicbox {
    width: 317px;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    
}

}