@import "config.scss";

.theatre {
    z-index: 98;
    position: relative;
}

.now2 {
    padding-top: 10px;
    padding-bottom: 5px;
    color: #ffffff;
    font-family: myriad;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
}

.c {
    text-align: center;
}

.infoan2 {
    color: #e81e30;
    font-family: myriad;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}

.prew2 {
    width: 135px;
    float: left;
    text-align: center;
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
    padding-top: 9px;
    padding-bottom: 9px;
    border: 0px;
    background-color: #e81e30;
    color: #ffffff;
    font-family: myriad;
    font-size: 18px;
    outline: none;
}

.cleft {
    clear: both;
    padding: 0px;
    margin: 0px;
}

.playerbox {
    float: left;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-top: 0px;
    color: #ffffff;
    font-family: myriad;
    font-weight: normal;
    font-style: normal;
}

.playerform {
    padding: 0px;
    margin: 0px;
    border: 0px;
}

.playerselect2 {
    width: 185px;
    border-radius: 0px;
    padding: 9px;
    padding-left: 10px;
    padding-right: 36px;
    margin: 0px;
    border: 0px;
    background-color: #333333;
    color: #ffffff;
    outline: none;
    margin-left: 10px;
    vertical-align: text-top;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(/ah_select.png);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position: right;
}

.lightbox {
    position: relative;
    float: right;
    width: 135px;
    padding-top: 9px;
    padding-bottom: 9px;
    margin: 0px;
    border: 0px;
    margin-right: 20px;
    margin-bottom: 10px;
    background-color: #333333;
    color: #ffffff;
    font-family: myriad;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    outline: none;
    cursor: default;
    text-align: center;
}

.cdark {
    width: 100%;
    height: 100%;
    background-color: #121212;
    z-index: 90;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
}

.nex2 {
    width: 135px;
    float: right;
    text-align: center;
    margin-right: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
    padding-top: 9px;
    padding-bottom: 9px;
    border: 0px;
    background-color: #e81e30;
    color: #ffffff;
    font-family: myriad;
    font-size: 18px;
    outline: none;
}

#video {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    padding-bottom: 56.25%;
    height: 0;
    width: 100%;
    background-color: #101010;
    position: relative;
}

.centerf {
    float: left;
    left: 50%;
    position: relative;
}

.centerf2 {
    float: left;
    left: -50%;
    position: relative;
}

.dl3 {
    float: left;
    text-align: center;
    color: #ffffff;
    background-color: #e81e30;
    font-style: normal;
    text-decoration: none;
    margin-bottom: 1px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 0px;
    padding: 9px;
    font-size: 19px;
    font-family: myriad;
    font-weight: normal;
    margin-bottom: 20px;
}

#video>iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (max-width: 480px) {

#video {
    height: 260px;
}

}