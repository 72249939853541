.searchbox {
    float: left;
    padding: 0px;
    margin: 0px;
    border: 0px;
    margin-top: 12px;
    margin-right: 10px;
    position: relative;
}

.searchform {
    font-family: arial;
    width: 365px;
    padding: 0px;
    margin: 0px;
    border: 0px;
    background-color: #333333;
    display: flex;
}

.loadingsa, .item.fx-none, .no-result {
    width: 365px;
    color: #757575;
    padding: 5px;
}

.item {
    cursor: pointer;
    overflow: hidden;
    padding: 10px;
    &:hover {
        background: #e81e30;
    }
}

.item .info {
    margin-left: 47px;
    color: #d4d4d4;
    font-size: 16px;
}

.item .info a.name {
    display: inline-block;
    margin-top: -3px;
    color: #d4d4d4;
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 3px;
}

.item .info .meta {
    overflow: hidden;
}

.show-more {
    width: 100%;
    background: #171717;
    padding: 5px;
    color: #fff;
    font-family: inherit;
    cursor: pointer;
    &:hover {
        color: #e81e30;
    }
}

.item .thumb {
    float: left;
    width: 40px;
    height: 50px;
    object-fit: cover;
}

.searchinput {
    color: #ffffff;
    width: 405px;
    height: 40px;
    padding: 0px;
    margin: 0px;
    border: 0px;
    background-color: #333333;
    outline: none;
    margin-left: 20px;
    vertical-align: text-top;
    font-style: italic;
    font-size: 16px;
    font-family: myriadlight;
}

.searchsubmit {
    width: 40px;
    height: 40px;
    padding: 0px;
    margin: 0px;
    border: 0px;
    background-color: #333333;
    background-image: url(/play.png);
    background-size: 40px 40px;
    vertical-align: text-top;
    outline: none;
}

.suggestions-cont {
    position: absolute;
    z-index: 99;
    border-radius: 3px;
    background: #333333;
    margin-top: 35px;
}

.suggestions-cont.hidden:not(:hover) {
    display: none;
}

@media (max-width: 1280px) {

.searchform, .loadingsa, .item.fx-none, .no-result {
    width: 820px;
}

.searchinput {
    width: 810px;
    margin-left: 20px;
}

}

@media (max-width: 960px) {

.searchform, .loadingsa, .item.fx-none, .no-result {
    width: 405px;
}

.searchinput {
    width: 420px;
    margin-left: 20px;
}

}

@media (max-width: 640px) {

.searchform, .loadingsa, .item.fx-none, .no-result {
    width: 225px;
}

.searchinput {
    width: 220px;
    margin-left: 10px;
}

}