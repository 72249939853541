@import "config.scss";

.gridpgboxmain {
    width: 960px;
    overflow: hidden;
    background-color: #333333;
    margin-bottom: 1px;
}

.gridpgbox {
    width: 960px;
    background-color: #121212;
    float: left;
    margin-bottom: -1px;
}

.gridvan {
    color: #ffffff;
    font-family: myriad;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}

.gridpgmain {
    /* padding-top: 3px; */
    /* padding-bottom: 1px; */
    width: 32px;
    height: 31px;
    background-color: #333333;
    float: left;
    text-align: center;
    font-size: 19px;
    font-style: normal;
    text-decoration: none;
    margin-top: 0px;
    /* margin-bottom: 1px; */
    /* margin-right: 1px; */
    font-family: myriad;
    font-weight: normal;
}

.gridpg {
    padding-top: 3px;
    padding-bottom: 1px;
    width: 31px;
    height: 26px;
    background-color: #121212;
}

.notmain.asd> div *, :after, :before {
    box-sizing: initial;}

.notmain.asd {border-top: 1px solid #333333;}

.lisa {
    margin-bottom: 1px;
    color: #ffffff;
    background-color: #121212;
    padding: 10px;
    padding-left: 20px;
    font-size: 16px;
    font-family: myriad;
    font-weight: normal;
    letter-spacing: 1px;
}

.gridv2main {
    width: 64px;
    background-color: #333333;
    float: right;
    text-align: center;
    font-size: 19px;
    font-style: normal;
    text-decoration: none;
    font-family: myriad;
    font-weight: normal;
    margin-right: 0px;
    padding-bottom: 1px;
    /* padding-left: 1px; */
    height: 30px;
}

.gridv2 {
    padding-top: 3px;
    padding-bottom: 4px;
    width: 63px;
    background-color: #121212;
    /* float: right; */
    text-align: center;
    font-size: 19px;
    font-style: normal;
    text-decoration: none;
    font-family: myriad;
    font-weight: normal;
    margin-right: 0px;
}

.lisbox {
    display: inline-block;
    width: 959px;
    background-color: #333333;
    float: left;
    margin-bottom: -1px;
    padding-right: 1px;
    padding-left: 1px;
}

@media (max-width: 1280px) {
.gridpgboxmain {
    width: 958px;
}

.lisbox {
    width: 958px;
    margin-bottom: 0px;
}

}

@media (max-width: 960px) {

.gridpgboxmain {
    width: 638px;
    margin-bottom: 1px;
}

.gridpgbox {
    width: 640px;
    float: left;
    margin-bottom: -1px;
}

.gridv2 {
    margin-left: 1px;
}

.gridv2main {
    /* padding-left: 1px; */
    width: 97px;
}

.lisbox {
    width: 638px;
}

}

@media (max-width: 640px) {

.gridpgboxmain {
    width: 317px;
}

.lisbox {
    width: 317px;
}

.gridv2main {
    /* padding-left: 0px; */
    width: 96px;
}

.gridv2 {
    margin-left: 0px;
}

.gridpgbox {
    width: 320px;
}

}