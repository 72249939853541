* {
    box-sizing: border-box;
}

*, :after, :before {
    box-sizing: border-box;
    border: 0 solid #e2e8f0;
}

body {
    margin: 0;
    font-family: monospace;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42857143;
    color: #e8e5e0;
    background-color: #121212;
}

ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
@font-face {font-family: myriad;src: url(/assets/font/MYRIADPRO-REGULAR.OTF);}@font-face {font-family: myriadlight;src: url(/assets/font/MyriadPro-Light.otf);}