.header {
    width: 100%;
    color: #333333;
    background-color: #121212;
    position: relative;
    height: 64px;
}

.headermain {
    width: 1280px;
    margin: 0 auto;
}


a {
    color: #282828;
    font-family: myriad;
    font-size: 19px;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
}

.logo {
    float: left;
    background-image: url(/weebcity_logo.png?r);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    margin-top: 12px;
    margin-left: 20px;
}

.menue {
    padding: 9px;
    padding-left: 0px;
    padding-right: 11px;
    display: block;
    float: right;
    margin-top: 9px;
    &:hover {
        color: #e81e30;
    }
}

.menua {
    color: #ffffff;
    font-family: myriadlight;
    font-size: 19px;
}

.burger {
    display: none;
    float: right;
    background-image: url(/ah_burger_idle.png);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    margin-top: 12px;
    margin-left: 10px;
    margin-right: 20px;
}

.burgermenu {
    width: 100%;
    background-color: #121212;
    float: right;
    margin-bottom: 1px;
    padding-bottom: 20px;
    padding-top: 10px;
    display: none;
}

.burgermenu.active {
    width: 100%;
    background-color: #121212;
    float: right;
    padding-bottom: 20px;
    margin: 0;
    padding: 0;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}

.burger.active {
    background-image: url(/ah_burger_active.png);
}

.line {
    width: 100%;
    height: 1px;
    background-color: #333333;
}

.menue2 {
    display: block;
    margin-top: 10px;
    font-size: 25px;
    font-family: myriadlight;
    font-weight: normal;
    text-align: center;
    &:hover {
        color: #e81e30;
    }
}

.menua {
    color: #ffffff;
    font-family: myriadlight;
    &:hover {
        color: #e81e30;
    }
}

@media (max-width: 1280px) {
    
.headermain {
    width: 960px;
}

.burger {
    display: block;
}

.menue {
    display: none;
    margin-right: 3px;
    margin-left: 3px;
    &:hover {
        color: #e81e30;
    }
}

}

@media (max-width: 960px) {

.headermain {
    width: 550px;
}

.logo {
    margin-left: 10px;
}

}

@media (max-width: 640px) {

.headermain {
    width: 360px;
}

.logo {
    margin-left: 10px;
}
    
}